import React from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

function Backoffice(props) {
  let history = useHistory();
  
  return (
    <div>
      <h1>Back Office</h1>
      
      <h2>Information Center</h2>
      <div><Button onClick={() => history.push('/main')}>Calibration Wall</Button></div>
      <div><Button onClick={() => history.push('/admin')}>Calibration Wall Admin</Button></div>
      <div><Button onClick={() => history.push('/backoffice/device')}>Search By Device</Button></div>
      <div><Button onClick={() => history.push('/backoffice/sensor')}>Search By Sensor</Button></div>
      <div><Button onClick={() => history.push('/backoffice/calibration-adjustment')}>Calibration Adjustment</Button></div>
    </div>
  )
}

export default Backoffice;