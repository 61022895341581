import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Spin } from 'antd';
import ReactEcharts from 'echarts-for-react';
import moment from 'moment';
import echartsOptions from './echartsOptions';
import get_sensor_data from './get_sensor_data';

function SingleSensor(props) {
  const { sensor, devices, from, to } = props;
  const [data, setData] = useState({ measurements: [], state: {}});

  const sortedMeasurements = _.sortBy(_.map(_.toArray(_.groupBy(data.measurements, 'time')), v => Object.assign({}, ...v)), 'time');
  const temperatures = _.map(sortedMeasurements,  m => ({value: [moment(m.time).format('YYYY/MM/DD H:mm:ss'), m.tempg]}));
  const raw = _.map(sortedMeasurements, m => ({value: [moment(m.time).format('YYYY/MM/DD H:mm:ss'), m.rt_raw || m.rt]}));
  const rawTc = _.map(sortedMeasurements,  m => ({value: [moment(m.time).format('YYYY/MM/DD H:mm:ss'), m.rt_raw_tc]}));
  const humidity = _.map(sortedMeasurements,  m => ({value: [moment(m.time).format('YYYY/MM/DD H:mm:ss'), m.rt_int_hum || m.humi]}));
  const device = _.find(_.filter(devices, d => _.has(d, 'id')), d => d.id === sensor.deviceId);

  useEffect(() => {
    get_sensor_data(sensor.id, from, to)
      .then(setData);
  }, [setData, from, to, sensor.id]);

  const calibrationValueEl = (<span style={{marginRight: '10px'}}>Calibration Value: {_.get(data, 'state.calibrationValue')}/{_.get(data, 'state.calibrationTemp')}</span>)

  return (
    <div style={{height: '100%'}}>
      <div style={{height: '10%', display: 'flex', justifyContent: 'space-between', paddingTop: '20px'}}>
        <div>
          <span style={{marginRight: '10px'}}>Sensor: {sensor.id}</span>
          <span style={{marginRight: '10px'}}>Device: {_.has(device, `name`) ? _.get(device, `name`) : sensor.deviceId}</span>
          <span style={{marginRight: '10px'}}>Port: {sensor.port}</span>
          {props.isCalibrationVisible && calibrationValueEl}
        </div>
      </div>

      {!_.isEmpty(data.state) || !_.isEmpty(data.measurements) ? (<ReactEcharts
        option={echartsOptions(raw, temperatures, rawTc, humidity, data.state.calibrationValue)}
        style={{height: '90%', width: '100%', minHeight: '500px'}}
      />) : <Spin />}
    </div>
  );
}

SingleSensor.defaultProps = {
  onCalibrationClick: _.noop,
  isCalibrationVisible: true
}

export default SingleSensor;