import config from '../../config';

function update_calibration_value(sensorId, value, temp, comment) {
  const token = window.localStorage.getItem('token'); 
  if(!sensorId) {
    return Promise.resolve({});
  }

  return fetch(
    `${config.server.url}/sensor/${sensorId}/calibration`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorize': token
      },
      body: JSON.stringify({value, temp, comment})
    }
  ).then(res => res.json())
  .then(payload => {
    if(payload.error && 
      (
        payload.error.includes('Invalid Credentials') ||
        payload.error.includes('Login Required')
      )){
        window.location = '/login';
      }

    return payload;
  });
}

export default update_calibration_value;