import React from 'react';
import _ from 'lodash';
import { Table, Button } from 'antd';

import style from './Stage1.module.css';

function FullTable(props) {
  const columns = [
    {
      title: 'Sys. Name',
      dataIndex: 'device_id',
      key: 'deviceId',
      render: (deviceId, record) => {
        const device = _.find(props.devices, {id: deviceId});

        return (<Button type="link" style={{color: "black"}} onClick={() => props.selectSensor(record)}>{_.get(device, 'name') || deviceId}</Button>)
      },
      sorter: {
        multiple: 1,
        compare: (a, b) => {
          const deviceA = _.find(props.devices, {id: a.device_id}) || {name: a.device_id};
          const deviceB = _.find(props.devices, {id: b.device_id}) || {name: b.device_id};
  
          const aName = deviceA.name;
          const bName = deviceB.name;

          return aName.localeCompare(bName);
        }
      },
      filters: _.map(_.sortBy(props.devices, 'name'), device => ({text: device.name || device.id, value: device.id})),
      onFilter: (value, record) => {
        return record.device_id === value;
      }
    },{
      title: 'Port',
      dataIndex: 'port',
      key: 'port',
      filters: '12345678'.split('').map(port => ({text: port, value: parseInt(port)})),
      onFilter: (value, record) => {
        return record.port === value;
      },
      sorter: {
        multiple: 2,
        compare: (a, b) => a.port - b.port
      },
      render: (index) => index
    },{
      title: 'Raw/Temperature',
      key: 'pressureTemperature',
      render: (v, record) => `${record.rt}/${record.tempg}`
    },{
      title: 'Min/Max',
      dataIndex: 'result1',
      key: 'minmax',
      render: v => {
        const rtResults = _.filter(v, r => _.get(r, 'rt_min') && _.get(r, 'rt_max'));

        return !_.isEmpty(rtResults) ? `${_.minBy(rtResults, 'rt_min').rt_min}/${_.maxBy(rtResults, 'rt_max').rt_max}` : '-'
      },
      sorter: {
        multiple: 3,
        compare: (a, b) => {
          if(_.isEmpty(a.result1) || _.isEmpty(b.result1)) return 0;
          
          const aMin = _.minBy(a.result1, 'rt_min').rt_min;
          const bMin = _.minBy(b.result1, 'rt_min').rt_min;

          return aMin-bMin;
        }
      },
    },
    {
      title: 'Clay Test',
      dataIndex: 'stage1',
      key: 'clayTest',
      render: ({clayTest}) => clayTest ? 'V' : 'X',
      filters: 'VX'.split('').map(approve => ({text: approve, value: approve})),
      onFilter: (value, record) => _.get(record, 'stage1.clayTest') ? 'V' === value : 'X' === value,
      defaultSortOrder: 'descend',
      sorter: {
        multiple: 5,
        compare: (a, b) => {
          if(_.get(a, 'stage1.clayTest') && !_.get(b, 'stage1.clayTest')) return 1;
          if(!_.get(a, 'stage1.clayTest') && _.get(b, 'stage1.clayTest')) return -1;
          return 0;
        },
      }
    },
    {
      title: 'Cable Test',
      dataIndex: 'stage1',
      key: 'cableTest',
      render: ({cableTest}) => cableTest === '' ? 'V' : 'X',
      filters: 'V,X'.split(',').map(filterBy => ({text: filterBy, value: filterBy})),
      onFilter: (value, record) => {
        if(!_.has(record, 'stage1.cableTest')) {
          return;
        }

        if(_.isNull(_.get(record, 'stage1.cableTest'))) {
          return 'X' === value;
        }

        if(_.isEmpty(_.get(record, 'stage1.cableTest'))) {
          return 'V' === value;
        } else {
          return 'X' === value;
        }
      },
      defaultSortOrder: 'ascend',
      sorter: {
        multiple: 6,
        compare: (a, b) => {
          if(_.get(a, 'stage1.isEmpty') || _.get(a, 'stage1.isEmpty')) {
            return;
          }
  
          if(_.get(a, 'stage1.cableTest') === null && _.get(b, 'stage1.cableTest') === null) {
            return 0;
          }

          if(_.get(a, 'stage1.cableTest') !== null && _.get(b, 'stage1.cableTest') === null) {
            return -1;
          }

          if(_.get(a, 'stage1.cableTest') === null && _.get(b, 'stage1.cableTest') !== null) {
            return 1;
          }

          return _.get(a, 'stage1.cableTest').localeCompare(_.get(b, 'stage1.cableTest'));
        }
      }
    },
    {
      title: 'X55',
      dataIndex: 'setAddr',
      key: 'setaddr',
      defaultSortOrder: 'descend',
      render: (v) => v ? 'V' : 'X',
      filters: 'XV'.split('').map(filterBy => ({text: filterBy, value: filterBy === 'V'})),
      onFilter: (value, record) => {
        return record.setAddr === value;
      },
      sorter: {
        multiple: 4,
        compare: (a, b) => {
          if(_.get(a, 'setAddr') && !_.get(b, 'setAddr')) return 1;
          if(!_.get(a, 'setAddr') && _.get(b, 'setAddr')) return -1;
          return 0;
        }
      }
    }
  ]
  
  let selectedRowKeys = props.selectedSensorKeys;
  
  if(_.some(props.dataSource, record => _.get(record, 'stage1.valid')) && props.selectedSensorKeys.length === 0 && props.panel.auto_pass_stage_1) {
    selectedRowKeys = _.map(_.filter(props.dataSource, record => _.get(record, 'stage1.valid')), 'id');

    props.onSelectSensors(selectedRowKeys);
  }

  return (
    <Table
      dataSource={props.dataSource}
      columns={columns}
      rowKey='id'
      pagination={false}
      showSorterTooltip={false}
      scroll={{y: 'calc(100vh - 162px)'}}
      rowClassName={(record) => {
        if(_.get(record, 'stage1.isEmpty')) return {
          [style.disabled]: true
        }

        return {
          [style.invalid]: !_.get(record, 'stage1.valid'),
          [style.valid]: _.get(record, 'stage1.valid')
        };
      }}
      rowSelection={{
        selectedRowKeys,
        onChange: (newSelectedRowKeys) => {
          props.onSelectSensors(newSelectedRowKeys);
        },
        getCheckboxProps: record => {
          if(_.get(record, 'stage1.isEmpty')) return {
            disabled: true
          }

          return {
            disabled: !_.get(record, 'stage1.valid')
          };
        },
      }}
    />
  )
}

export default FullTable;