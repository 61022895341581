import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import config from '../../config';
import style from './Login.module.css';


export default function Login() {
  const location = useLocation();
  const [error, setError] = useState('');
  const query = new URLSearchParams(location.search);

  if(query.get('code') && !error) {
    fetch(`${config.server.url}/google/callback${location.search}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(res => {
        if(res.error) {
          throw new Error(res.error);
        }
        window.localStorage.setItem('token', res.token);
        window.location = '/backoffice'
      })
      .catch(err => {
        setError(err.message);
      });

    return (
      <div>
        loading
      </div>
    );
  }

  return (
    <div>
      <div className={style.error}>{error}</div>
      <div className={style.googleBtn} 
      onClick={
        () => 
          window.location = `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuser.emails.read%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadmin.directory.user.readonly&response_type=code&client_id=501414392851-mrhtmppnaqrupt8rk133lu55vc50dd1i.apps.googleusercontent.com&redirect_uri=${encodeURIComponent(config.client.url + '/')}login`
        }>
        <div className={style.googleIconWrapper}>
          <img alt="google-logo" className={style.googleIcon} src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
        </div>
        <p  className={style.btnText}><b>Sign in with google</b></p>
      </div>
    </div>
  )
}