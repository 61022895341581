import React from 'react';
import _ from 'lodash';
import { Table, Button } from 'antd';

import style from './Stage1.module.css';


function EmptyTable(props) {
  const columns = [
    {
      title: 'Sys. Name',
      dataIndex: 'device_id',
      key: 'deviceId',
      render: (deviceId, record) => {
        const device = _.find(props.devices, {id: deviceId});

        return (<Button type="link" style={{color: "black"}} onClick={() => props.selectSensor(record)}>{_.get(device, 'name') || deviceId}</Button>)
      },
      sorter: {
        multiple: 1,
        compare: (a, b) => {
          const deviceA = _.find(props.devices, {id: a.device_id}) || {name: a.device_id};
          const deviceB = _.find(props.devices, {id: b.device_id}) || {name: b.device_id};
  
          const aName = deviceA.name;
          const bName = deviceB.name;

          return aName.localeCompare(bName);
        }
      },
      filters: _.map(_.sortBy(props.devices, 'name'), device => ({text: device.name || device.id, value: device.id})),
      onFilter: (value, record) => {
        return record.device_id === value;
      }
    },{
      title: 'Port',
      dataIndex: 'port',
      key: 'port',
      filters: '12345678'.split('').map(port => ({text: port, value: parseInt(port)})),
      onFilter: (value, record) => {
        return record.port === value;
      },
      sorter: {
        multiple: 2,
        compare: (a, b) => a.port - b.port
      },
      render: (index) => index
    },{
      title: 'Raw/Temperature',
      key: 'pressureTemperature',
      render: (v, record) => `${record.rt}/${record.tempg}`
    }, {
      title: 'Min/Max',
      dataIndex: 'result1',
      key: 'minmax',
      render: v => '-',
    },
    {
      title: 'Clay Test',
      dataIndex: 'result1',
      key: 'clayTest',
      render: v =>  '-',
    },
    {
      title: 'Cable Test',
      dataIndex: 'result1',
      key: 'cableTest',
      render: v => '-',
    },
    {
      title: 'X55',
      dataIndex: 'setAddr',
      key: 'setaddr',
      render: (v) => v ? 'V' : 'X',
      filters: 'XV'.split('').map(filterBy => ({text: filterBy, value: filterBy === 'V'})),
      onFilter: (value, record) => {
        return record.setAddr === value;
      },
      sorter: {
        multiple: 6,
        compare: (a, b) => {
          if(_.get(a, 'setAddr') && !_.get(b, 'setAddr')) return 1;
          if(!_.get(a, 'setAddr') && _.get(b, 'setAddr')) return -1;
          return 0;
        }
      }
    }
  ]
  

  return (
    <Table
      dataSource={props.dataSource}
      columns={columns}
      rowKey='id'
      pagination={false}
      showSorterTooltip={false}
      scroll={{y: 'calc(100vh - 162px)'}}
      rowClassName={(record) => {
        return {
          [style.disabled]: true
        }
      }}
      rowSelection={{
        selectedRowKeys: [],
        getCheckboxProps: () => {
          return {
            disabled: true
          };
        },
      }}
    />
  )
}

export default EmptyTable;