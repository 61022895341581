import config from '../../../config';

function get_sensor_data(sensorId, from, to) {
  const token = window.localStorage.getItem('token'); 
  if(!sensorId) {
    return Promise.resolve({});
  }

  return fetch(
    `${config.server.url}/sensor/${sensorId}?from=${from}&to=${to}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorize': token
      }
    }
  ).then(res => res.json());
}

export default get_sensor_data;