import React, { useState } from 'react';
import _ from 'lodash';
import { useEffect } from 'react';
import get_devices from '../../Admin/get_devices';
import { Button, Select } from 'antd';
import CalibrationAdjustmentDevice from './CalibrationAdjustmentDevice';
import get_sensors from '../get_sensors';

function CalibrationAdjustment(props) {

  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [sensors, setSensors] = useState([]);

  const initialData = () => {
    (async () => {
      const {devices} = await get_devices();
      const {sensors} = await get_sensors();    
      const devicesEmulation = _.map(sensors, sensor => ({
        name: sensor.id,
        id: sensor.id,
        rt1_raw: {
          id: sensor.id
        }
      }));
      setSensors(sensors);
      setDevices(_.uniqBy(_.filter([...devices, ...devicesEmulation], d=> _.isObject(d) && _.isString(d.id)), 'id'));
    })();
  };

  useEffect(initialData, []);

  return (
    <div>
      <Button onClick={initialData}>Refresh</Button>

      <Select
        style={{width: '100%'}}
        showSearch
        placeholder="Device"
        onChange={setSelectedDevice}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {devices.filter(device => _.has(device, 'id')).map(device => (
          <Select.Option key={device.id}>{device.name || device.id}</Select.Option>
        ))}
      </Select>
      <CalibrationAdjustmentDevice onChangeCalibration={initialData} sensors={sensors} devices={devices} deviceId={selectedDevice} />
    </div>
  )
}

export default CalibrationAdjustment;