import config from '../../config';
import _ from 'lodash';

function updatePanel(panelId, configuration) {
  const token = window.localStorage.getItem('token'); 

  return fetch(
    `${config.server.url}/panel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorize': token
      },
      body: JSON.stringify({
        ..._.isNumber(panelId) ? {id: panelId} : {},
        ...configuration,
        devices: configuration.devices.join(',')
      })
    }
  ).then(res => res.json())
  .then(payload => {
    if(payload.error && 
      (
        payload.error.includes('Invalid Credentials') ||
        payload.error.includes('Login Required')
      )){
        window.location = '/login';
      }

    return payload;
  });
}

export default updatePanel;