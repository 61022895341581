import React, { useState, useEffect } from 'react';
import get_sensors from './get_sensors';
import { Select } from 'antd';
import SensorSingleSensor from './SensorSingleSensor';
import get_devices from '../Admin/get_devices';
import { Route, Router, Switch, useHistory } from 'react-router-dom';

function Sensor() {
  const [sensors, setSensors] = useState([]);
  const [devices, setDevices] = useState([]);
  const history = useHistory();
  const setSelectedSensor = (selectedSensor) => {
    const id = sensors[selectedSensor].id;

    history.push(`/backoffice/sensor/${id}`);
  }

  useEffect(() => {
    get_devices()
      .then(({devices}) => setDevices(devices))
      .then(get_sensors)
      .then(({sensors}) => setSensors(sensors));
  }, []);

  return (
    <div>
      <Select
        style={{width: '100%'}}
        showSearch
        placeholder="Sensor"
        onChange={setSelectedSensor}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {sensors.map((sensor, i) => (
          <Select.Option key={i}>{sensor.id || 'undefined'}</Select.Option>
        ))}
      </Select>


      <Router history={history} basename="/backoffice/sensor">
        <Switch>
          <Route path='/backoffice/sensor/:sensorId'>
            <SensorSingleSensor devices={devices} sensors={sensors} />
          </Route>
        </Switch>
      </Router>
        
    </div>
  )
}

export default Sensor;