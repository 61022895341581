import config from '../config';
import _ from 'lodash';


function hideDisconnectedStage3Sensors(sensors, panel) {
  const stage2SensorsIds = _.get(panel, 'stage_2_selected_sensors') ? _.get(panel, 'stage_2_selected_sensors').split(',') : [];
  const calibratedSensorIds = _.get(panel, 'stage_3_sensors_order') ? _.get(panel, 'stage_3_sensors_order').split(',') : [];
  const notCalibratedSensorIds = _.difference(stage2SensorsIds, calibratedSensorIds)
  const notCalibratedSensors = sensors.filter(sensor => !_.get(sensor, 'disconnect') && !_.isEmpty(sensor) && notCalibratedSensorIds.includes(sensor.id));
  const calibratedSensors = sensors.filter(sensor => !_.isEmpty(sensor) && calibratedSensorIds.includes(sensor.id));
  const connectedCalibratedSensors = _.flatten(_.filter(_.chunk(calibratedSensors, 2), pair => {
    return !(pair[0].disconnect && pair[1].disconnect);
  }));

  return [...notCalibratedSensors, ...connectedCalibratedSensors];
}

function pairSensorsAction(sensors, panel) {
  const toPair = _(sensors).sortBy(s => s.rt).reverse().value();
  const pairRange = panel.stage_3_pair_range || 30;

  let j = 1;
  for (let i = 0; i < toPair.length; i++) {
    const sensor = toPair[i];
    let x = i+1;
    let nextSensor = toPair[x];
    
    while(nextSensor && nextSensor.pair > 0) {
      nextSensor = toPair[++x];
    }

    if(!nextSensor) {
      break;
    }

    if(sensor.pair > 0) {
      continue;
    }

    if(Math.abs(sensor.calibrationValue - nextSensor.calibrationValue) <= pairRange) {
      sensor.pair = j;
      nextSensor.pair = j;
      j++;
      i++; // jump to next sensor
      continue;
    }
  }

  return toPair;
}

export const getPanel = dispatch => id => {
  const token = window.localStorage.getItem('token'); 
  dispatch({type: 'LOAD_PANEL'});
  return fetch(
    `${config.server.url}/panel/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorize': token
      }
    }
  ).then(res => res.json())
  .then(payload => {
    if(payload.error) {
      throw new Error(payload.error);
    }

    return payload;
  })
  .then(payload => dispatch({type: 'SET_PANEL', payload}))
  .catch(error => dispatch({type: 'ERROR', error}));
}

export const setPanel = (payload) => ({type: 'SET_PANEL', payload});

export const startStage = dispatch => (panelId, stage) => {
  const token = window.localStorage.getItem('token'); 

  dispatch({type: 'CHANGE_STAGE'});
  return fetch(
    `${config.server.url}/panel/${panelId}/stage`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorize': token
      },
      body: JSON.stringify({
        stage
      })
    }  ).then(res => res.json())
  .then(payload => {
    if(payload.error) {
      throw new Error(payload.error);
    }
    
    return payload;
  })
  .then(({panel}) => dispatch({type: 'SET_PANEL_DETAILS', payload: panel}))
  .catch(error => dispatch({type: 'ERROR', error}));
}


export const stopStage = dispatch => (panelId, stage) => {
  const token = window.localStorage.getItem('token'); 
  const toStage = stage === 1 ? 0 : stage - 0.5;

  dispatch({type: 'CHANGE_STAGE'});
  return fetch(
    `${config.server.url}/panel/${panelId}/stage`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorize': token
      },
      body: JSON.stringify({
        stage: toStage
      })
    }  ).then(res => res.json())
    .then(payload => {
      if(payload.error) {
        throw new Error(payload.error);
      }
      
      return payload;
    })
    .then(({panel}) => dispatch({type: 'SET_PANEL_DETAILS', payload: panel}))
  .catch(error => dispatch({type: 'ERROR', error}));
}

export const saveStageSensors = dispatch => (panelId, stage, sensors) => {
  const token = window.localStorage.getItem('token'); 

  dispatch({type: 'CHANGE_STAGE_SENSORS'});
  return fetch(
    `${config.server.url}/panel/${panelId}/stage/${stage}/sensors`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorize': token
      },
      body: JSON.stringify({
        sensors
      })
    }).then(res => res.json())
    .then(payload => {
      if(payload.error) {
        throw new Error(payload.error);
      }
      
      return payload;
    })
    .then(({panel}) => dispatch({type: 'SET_PANEL_DETAILS', payload: panel}))
  .catch(error => dispatch({type: 'ERROR', error}));
}

export const pairSensors = dispatch => async (panelId, results) => {
  const token = window.localStorage.getItem('token'); 

  dispatch({type: 'PAIR_SENSORS'});

  const {panel} = await fetch(
    `${config.server.url}/panel/${panelId}/stage`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorize': token
      },
      body: JSON.stringify({
        stage: 3
      })
    }).then(res => res.json())
    .then(payload => {
      if(payload.error) {
        throw new Error(payload.error);
      }
      
      return payload;
    })
    .catch(error => dispatch({type: 'ERROR', error}));

  const { sensors } = await fetch(
    `${config.server.url}/panel/${panelId}/sensors`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorize': token
      }
    }
  ).then(res => res.json())
  .then(payload => {
    if(payload.error) {
      throw new Error(payload.error);
    }
    
    return payload;
  })
  .catch(error => dispatch({type: 'ERROR', error}));



  dispatch({type: 'SET_SENSORS', payload: sensors})

  const sensorsOrderIds = _(pairSensorsAction(hideDisconnectedStage3Sensors(sensors, panel), panel)).filter(sensor => _.has(sensor, 'pair')
    ).sortBy('pair').map('id').value();

  return fetch(
    `${config.server.url}/panel/${panelId}/sensors/freeze`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorize': token
      },
      body: JSON.stringify({
        sensors: sensorsOrderIds.join(','),
        results
      })
    }
  ).then(res => res.json())
  .then(payload => {
    if(payload.error) {
      throw new Error(payload.error);
    }
    
    return payload;
  })
  .then(({panel}) => dispatch({type: 'SET_PANEL_DETAILS', payload: panel}))
  .catch(error => dispatch({type: 'ERROR', error}));
}


export const unlockPanel = dispatch => id => {
  const token = window.localStorage.getItem('token'); 

  return fetch(
    `${config.server.url}/panel/${id}/unlock-reset`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorize': token
      }
    }
  ).then(res => res.json())
  .then(payload => {
    if(payload.error) {
      throw new Error(payload.error);
    }

    return payload;
  })
  .then(({panel}) => dispatch({type: 'SET_PANEL_DETAILS', payload: panel}))
  .catch(error => dispatch({type: 'ERROR', error}));
}


export const setBatchNumber = dispatch => (panelId, batchNumber) => {
  const token = window.localStorage.getItem('token'); 

  return fetch(
    `${config.server.url}/panel/${panelId}/batch`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorize': token
      },
      body: JSON.stringify({
        batchNumber
      })
    }
  ).then(res => res.json())
  .then(payload => {
    if(payload.error) {
      throw new Error(payload.error);
    }
    
    return payload;
  })
  .then(({panel}) => dispatch({type: 'SET_PANEL_DETAILS', payload: panel}))
  .catch(error => dispatch({type: 'ERROR', error}));
}
