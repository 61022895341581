import React from 'react';
import _ from 'lodash';
import { Card, Input, Button, Form, InputNumber } from 'antd';
import style from './CalibrationAdjustment.module.css';
import update_calibration_value from '../update_calibration_value';
import moment from 'moment';

function CalibrationAdjustmentSensor(props) {
  const { sensor } = props;
  const [form] = Form.useForm();

  if(_.isString(sensor.calibrationSource) && sensor.calibrationSource.includes('error')) {
    return (
      <div className={style.manualCalibrationBox}>
        <div className={style.manualCalibrationValues}>
          <Card title={`Error`} style={{ width: 612, height: 316, marginRight: '10px'}}>
            {sensor.calibrationSource}
          </Card>
        </div>
        <Card title={`RT${sensor.port} ${sensor.id}`} style={{ width: 612 }}>
          <Form form={form} name={sensor.id} onFinish={(data) => update_calibration_value(sensor.id, data.rt, data.temp, data.comment).then(() => {
            form.resetFields();
            props.onChangeCalibration && props.onChangeCalibration();
            })}>
            <div className={style.defaultTitle}>Value ({props.deviceName})</div>
            <Form.Item name="rt">
              <InputNumber step="0.1" />
            </Form.Item>
            <div className={style.defaultTitle}>Temperature ({props.deviceName})</div>
            <Form.Item name="temp">
              <InputNumber step="0.1" />
            </Form.Item>
            <div className={style.defaultTitle}>Event Log ({props.deviceName})</div>
            <Form.Item name="comment">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button className={style.sendButton} htmlType="submit">send</Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    )
  }

  return (
    <div className={style.manualCalibrationBox}>
      <div className={style.manualCalibrationValues}>
        <Card title={`RT${sensor.port} Max`} style={{ width: 306, height: 316, marginRight: '10px'}}>
          <div>
            <div className={style.lastValueTitle}>Last Value</div>
            <div className={style.lastValueValueWrapper}><span style={{color: props.color || 'rgb(55, 214, 122)'}} className={style.lastValueValue}>{sensor.calibrationValue}</span></div>
            <div className={style.lastValueTime}>{!_.isEmpty(props.sensorDetail) && moment(_.last(props.sensorDetail).time).format('DD/MM/YYYY HH:mm')}</div>
          </div>
        </Card>
        <Card title={`RT${sensor.port} Temp`} style={{ width: 306, height: 316 }}>
          <div>
            <div className={style.lastValueTitle}>Last Value</div>
            <div className={style.lastValueValueWrapper}><span style={{color: props.color || 'rgb(55, 214, 122)'}} className={style.lastValueValue}>{sensor.calibrationTemp}</span>°C</div>
            <div className={style.lastValueTime}>{!_.isEmpty(props.sensorDetail) && moment(_.last(props.sensorDetail).time).format('DD/MM/YYYY HH:mm')}</div>
          </div>
        </Card>
      </div>
        <Card title={`RT${sensor.port} ${sensor.id}`} style={{ width: 612 }}>
          <Form form={form} name={sensor.id} onFinish={(data) => update_calibration_value(sensor.id, data.rt, data.temp, data.comment).then(() => {
            form.resetFields();
            props.onChangeCalibration && props.onChangeCalibration();
            })}>
            <div className={style.defaultTitle}>Value ({props.deviceName})</div>
            <Form.Item name="rt">
              <InputNumber step="0.1" />
            </Form.Item>
            <div className={style.defaultTitle}>Temperature ({props.deviceName})</div>
            <Form.Item name="temp">
              <InputNumber step="0.1" />
            </Form.Item>
            <div className={style.defaultTitle}>Event Log ({props.deviceName})</div>
            <Form.Item name="comment">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button className={style.sendButton} htmlType="submit">send</Button>
            </Form.Item>
          </Form>
        </Card>
    </div>
  )
}

export default CalibrationAdjustmentSensor;