const error = (state = {error: null}, action) => {
  switch (action.type) {
    case 'ERROR':
      return action.error;
    default:
      return state
  }
}

export default error;
