import React from 'react';
import _ from 'lodash';
import { Table, Button } from 'antd';
import style from './Stage2.module.css';


function FullTable(props) {
  const columns = [{
    title: 'Sys. Name',
    dataIndex: 'device_id',
    key: 'deviceId',
    render: (deviceId, record) => {
      const device = _.find(props.devices, {id: deviceId});

      return (<Button type="link" style={{color: "black"}} onClick={() => props.selectSensor(record)}>{_.get(device, 'name') || deviceId}</Button>)
    },
    sorter: {
      multiple: 1,
      compare: (a, b) => {
        const deviceA = _.find(props.devices, {id: a.device_id}) || {name: a.device_id};
        const deviceB = _.find(props.devices, {id: b.device_id}) || {name: b.device_id};

        const aName = deviceA.name;
        const bName = deviceB.name;

        return aName.localeCompare(bName);
      }
    },
    filters: _.map(_.sortBy(props.devices, 'name'), device => ({text: device.name || device.id, value: device.id})),
    onFilter: (value, record) => {
      return record.device_id === value;
    }
  },{
      title: 'Port',
      dataIndex: 'port',
      key: 'port',
      filters: '12345678'.split('').map(port => ({text: port, value: parseInt(port)})),
      onFilter: (value, record) => {
        return record.port === value;
      },
      sorter: {
        multiple: 2,
        compare: (a, b) => a.port - b.port
      },
      render: (index) => index
    },{
      title: 'Raw/Temperature',
      key: 'pressureTemperature',
      render: (v, record) => `${record.rt}/${record.tempg}`
    },{
      title: 'Min/Max',
      dataIndex: 'result2',
      key: 'minmax',
      render: v => {
        const rtResults = _.filter(v, r => _.get(r, 'rt_min') && _.get(r, 'rt_max'));

        return !_.isEmpty(rtResults) ? `${_.minBy(rtResults, 'rt_min').rt_min}/${_.maxBy(rtResults, 'rt_max').rt_max}` : '-'
      },
      sorter: {
        multiple: 3,
        compare: (a, b) => {
          if(_.isEmpty(a.result2) || _.isEmpty(b.result2)) return 0;

          const aMin = _.minBy(a.result2, 'rt_min').rt_min;
          const bMin = _.minBy(b.result2, 'rt_min').rt_min;

          return aMin-bMin;
        }
      },
    }, {
      title: 'Stability',
      dataIndex: 'stage2',
      key: 'stabilityTest',
      render: v => {

        const isStable = v.stabilityTest;

        if(!isStable) {
          return 'X';
        }
        
        return 'V';
      },
      filters: 'VX'.split('').map(approve => ({text: approve, value: approve})),
      onFilter: (value, record) => {
        if(!_.get(record, 'stage2.stabilityTest')) {
          return 'X' === value;
        }
        
        return 'V' === value;
      },
      defaultSortOrder: 'ascend',
      sorter: {
        multiple: 4,
        compare: (a, b) => {
          if(_.get(a, 'stage2.stabilityTest') && !_.get(b, 'stage2.stabilityTest')) {
            return -1;
          } else if(_.get(b, 'stage2.stabilityTest') && !_.get(a, 'stage2.stabilityTest')) {
            return 1;
          }

          return 0;
        }
      }
    },{
      title: 'Range',
      dataIndex: 'stage2',
      key: 'rangeTest',
      render: ({clayTest}) => clayTest ? 'V' : 'X',
      filters: 'VX'.split('').map(approve => ({text: approve, value: approve})),
      onFilter: (value, record) => _.get(record, 'stage2.clayTest') ? 'V' === value : 'X' === value,
      defaultSortOrder: 'descend',
      sorter: {
        multiple: 5,
        compare: (a, b) => {
          if(_.get(a, 'stage1.clayTest') && !_.get(b, 'stage1.clayTest')) return 1;
          if(!_.get(a, 'stage1.clayTest') && _.get(b, 'stage1.clayTest')) return -1;
          return 0;
        },
      }
    },
    {
      title: 'Noise',
      dataIndex: 'stage2',
      key: 'noiseTest',
      render: ({cableTest}) => cableTest === '' ? 'V' : 'X',
      filters: 'V,X'.split(',').map(filterBy => ({text: filterBy, value: filterBy})),
      onFilter: (value, record) => {
        if(!_.has(record, 'stage2.cableTest')) {
          return;
        }

        if(_.isNull(_.get(record, 'stage2.cableTest'))) {
          return 'X' === value;
        }

        if(_.isEmpty(_.get(record, 'stage2.cableTest'))) {
          return 'V' === value;
        } else {
          return 'X' === value;
        }
      },
      defaultSortOrder: 'ascend',
      sorter: {
        multiple: 6,
        compare: (a, b) => {
          if(_.get(a, 'stage2.isEmpty') || _.get(a, 'stage2.isEmpty')) {
            return;
          }
  
          return _.get(a, 'stage2.cableTest').localeCompare(_.get(b, 'stage2.cableTest'));
        }
      }
    }
  ]
  
  let selectedRowKeys = props.selectedSensorKeys;

  if(_.some(props.dataSource, record => _.get(record, 'stage2.valid')) && props.selectedSensorKeys.length === 0 && props.panel.auto_pass_stage_1) {
    selectedRowKeys = _.map(_.filter(props.dataSource, record => _.get(record, 'stage2.valid')), 'id');

    props.onSelectSensors(selectedRowKeys);
  }


  return (
    <Table
      dataSource={props.dataSource}
      columns={columns}
      rowKey='id'
      pagination={false}
      showSorterTooltip={false}
      scroll={{y: 'calc(100vh - 162px)'}}
      rowClassName={(record) => {
        if(_.get(record, 'stage2.isEmpty')) return {
          [style.disabled]: true
        }


        return {
          [style.invalid]: !_.get(record, 'stage2.valid'),
          [style.valid]: _.get(record, 'stage2.valid')
        };
      }}
      rowSelection={{
        selectedRowKeys,
        onChange: (newSelectedRowKeys) => {
          props.onSelectSensors(newSelectedRowKeys);
        },
        getCheckboxProps: record => {
          if(_.get(record, 'stage2.isEmpty')) return {
            disabled: true
          }

          return {
            disabled: !_.get(record, 'stage2.valid')
          };

        },
      }}
    />
  )
}

export default FullTable;