import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Spin, DatePicker, Button } from 'antd';
import ReactEcharts from 'echarts-for-react';
import echartsOptions from './echartsOptions';
import get_sensor_data_by_device from './get_sensor_data_by_device';
import { ReloadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

function SingleSensorByDevice(props) {
  const history = useHistory();
  const { port, device, from, to } = props;
  const [data, setData] = useState({ measurements: null, state: null});
  const singleSensorState = _.find(data.state, s => s.port === port);
  const sortedMeasurements = _.sortBy(_.map(_.toArray(_.groupBy(data.measurements, 'time')), v => Object.assign({}, ...v)), 'time');
  const temperatures = _.map(sortedMeasurements,  m => ({value: [moment(m.time).format('YYYY/MM/DD H:mm:ss'), m.tempg]}));
  const raw = _.map(_.filter(sortedMeasurements), m => ({value: [moment(m.time).format('YYYY/MM/DD H:mm:ss'), m.rt || m.rt_raw]}));
  const rawTc = _.map(sortedMeasurements,  m => ({value: [moment(m.time).format('YYYY/MM/DD H:mm:ss'), m.rt_raw_tc]}));
  const humidity = _.map(sortedMeasurements,  m => ({value: [moment(m.time).format('YYYY/MM/DD H:mm:ss'), m.rt_int_hum || m.humi]}));
  const handleClickGraph = ({componentType, value}) => {
    if(componentType === 'markPoint') {
      history.push(`/backoffice/sensor/${value}`);
    }
  }
  const sensorChangeMarker = _.reduce(data.measurements, (markers, measurement, i) => {
    if(!_.has(measurement, 'sensor_id')) {
      return markers;
    }

    if(!_.isEmpty(markers) && _.last(markers).name === measurement.sensor_id) {
      return markers;
    }

    if(_.isNull(measurement.rt || measurement.rt_raw)) {
      return markers;
    }

    const marker = {
      name: measurement.sensor_id,
      value: `${measurement.sensor_id}`,
      coord: [moment(measurement.time).format('YYYY/MM/DD H:mm:ss'), measurement.rt || measurement.rt_raw]
    }

    return [...markers, marker];
  }, []);

  useEffect(() => {
    let isCancelled = false;
    get_sensor_data_by_device(device.id, port, from, to)
      .then(data => !isCancelled && setData(data));
    
    return () => {
      isCancelled = true;
    };
  }, [setData, from, to, port, device.id]);
  const calibrationValueEl = (<span style={{marginRight: '10px'}}>Calibration Value: {_.get(singleSensorState, 'calibrationValue')}/{_.get(singleSensorState, 'calibrationTemp')}</span>)

  return (
    <div style={{height: '100%'}}>
      <div style={{height: '10%', display: 'flex', justifyContent: 'space-between', paddingTop: '20px'}}>
        <div>
          <span style={{marginRight: '10px'}}>Device: {_.has(device, `name`) ? _.get(device, `name`) : device.id}</span>
          <span style={{marginRight: '10px'}}>Port: {port}</span>
          <a href={`/backoffice/sensor/${_.get(device, `rt${port}_raw.id`) || _.get(device, `rt${port}.id`)}`} rel="noopener noreferrer" target="_blank"><span style={{marginRight: '10px'}}>Sensor: {_.get(singleSensorState, 'device_id') !== device.id && <span>*</span>}{ _.get(device, `rt${port}_raw.id`) || _.get(device, `rt${port}.id`) }</span></a>
          {calibrationValueEl}
        </div>
      </div>

      {!_.isNull(data.measurements) ? (<ReactEcharts
        onEvents={{click: handleClickGraph}}
        option={echartsOptions(raw, temperatures, rawTc, humidity, sensorChangeMarker)}
        style={{height: '90%', width: '100%', minHeight: '500px'}}
      />) : <Spin />}
    </div>
  );
}

function DeviceSingleSensor(props) {
  const [from, setFrom] = useState(moment().subtract(7,'d').toISOString());
  const [to, setTo] = useState(moment().toISOString());
  
  return (
    <div> 
    <DatePicker.RangePicker
      defaultValue={[moment().subtract(7,'d'), moment()]}
      ranges={{
        Today: [moment().startOf('day'), moment()],
        'Last 3 days': [moment().subtract(3, 'days'), moment()],
        'Last Week': [moment().subtract(1, 'week'), moment()],
        'Last Month': [moment().subtract(1, 'month'), moment()],
        'This Month': [moment().startOf('month'), moment()],
        'All': [moment().subtract(20, 'years'), moment()]
      }}
      onChange={(dates) => {
        if(!dates) {
          return;
        }
        const [fromDatePicker, toDatePicker] = dates;
        setFrom(fromDatePicker.toISOString());
        setTo(toDatePicker.toISOString());
      }}
    />
    <Button onClick={() => setTo(moment().toISOString())}><ReloadOutlined /></Button>
      <SingleSensorByDevice port={props.sensorPort}
      device={props.device}
      from={from}
      to={to}
      />
    </div>
  )
}

DeviceSingleSensor.defaultProps = {
  device: {id: null}
}

export default DeviceSingleSensor;