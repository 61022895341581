import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';

import { Tabs, Modal, Button } from 'antd';
import Countdown from 'react-countdown';

import style from './Main.module.css';
import Stage1 from './Stage1/Stage1';
import Stage2 from './Stage2/Stage2';
import Stage3 from './Stage3/Stage3';
import SingleSensor from './SingleSensor/SingleSensor';

import { useParams } from 'react-router-dom';

const { TabPane } = Tabs;
const TIME_UNTIL_UPDATE = 1000;

function hideDisconnectedStage3Sensors(sensors, panel) {
  const stage2SensorsIds = _.get(panel, 'stage_2_selected_sensors') ? _.get(panel, 'stage_2_selected_sensors').split(',') : [];
  const calibratedSensorIds = _.get(panel, 'stage_3_sensors_order') ? _.get(panel, 'stage_3_sensors_order').split(',') : [];
  const notCalibratedSensorIds = _.difference(stage2SensorsIds, calibratedSensorIds)
  const notCalibratedSensors = sensors.filter(sensor => !_.get(sensor, 'disconnect') && !_.isEmpty(sensor) && notCalibratedSensorIds.includes(sensor.id));
  const calibratedSensors = calibratedSensorIds.map(sensorId => _.find(sensors, {id: sensorId}));
  const connectedCalibratedSensors = _.flatten(_.filter(_.chunk(calibratedSensors, 2), pair => {
    return !(pair[0].disconnect && pair[1].disconnect);
  }));

  return [...notCalibratedSensors, ...connectedCalibratedSensors];
}


function Main(props) {
  const [selectedSensor, setSelectedSensor] = useState({});
  const [currentTab, setCurrentTab] = useState(1);
  const params = useParams();
  const panelId = params.panelId || 1;

  const updateData = () => {
    props.getPanel(panelId);
  };
  
  const updateSensorsPoll = () => {
    props.pollSensorsStart(panelId);
  }

  useEffect(updateData, []);
  useEffect(updateSensorsPoll, []);

  const stage0SensorsIds = _.get(props, 'panel.stage_0_selected_sensors') ? _.get(props, 'panel.stage_0_selected_sensors').split(',') : [];
  const stage1SensorsIds = _.get(props, 'panel.stage_1_selected_sensors') ? _.get(props, 'panel.stage_1_selected_sensors').split(',') : [];
  const stage2SensorsIds = _.get(props, 'panel.stage_2_selected_sensors') ? _.get(props, 'panel.stage_2_selected_sensors').split(',') : [];
  const stage3SensorsIds = _.get(props, 'panel.stage_3_sensors_order') ? _.get(props, 'panel.stage_3_sensors_order').split(',') : [];
  const successStage1 = stage1SensorsIds.length || _.filter(props.sensors, sensor => _.get(sensor,'stage1.valid')).length;
  const successStage2 = stage2SensorsIds.length || _.filter(props.sensors, sensor => _.get(sensor,'stage1.valid') && _.get(sensor,'stage2.valid')).length;
  const successStage3 = _.isEmpty(stage3SensorsIds) ? 0 : stage3SensorsIds.length;

  const statistics = `Stage 1 - ${successStage1 || 0}/${props.sensors.length} | Stage 2 - ${successStage2 || 0}/${stage1SensorsIds.length} | Stage 3 - ${successStage3}/${stage2SensorsIds.length}`;

  return (
    <Fragment>
      <Modal
        visible={_.has(props, 'error.message')}
        centered={true}
        footer={[]}
        width="500px"
      >
        <div style={{textAlign: 'center'}}>A Connection Error Has occur </div>
        <div style={{textAlign: 'center'}}>Please refresh your browser</div>
        <br />
        <br />
        <div style={{textAlign: 'center'}}><Button onClick={()=> window.location.reload()}>Refresh</Button></div>
      </Modal>
      <Modal
        visible={!_.isEmpty(selectedSensor)}
        onCancel={() => {
          setSelectedSensor({});
          updateSensorsPoll();
        }}
        centered={true}
        footer={[]}
        width="auto"
        className="sensor_data_modal"
        style={{
          position: 'absolute',
          top: 0,
          left: '3%',
          right: '3%',
          bottom: 0,
          maxWidth: '100%',
          height: '90vh'
        }}
        bodyStyle={{
          overflow: 'auto'
        }}
      >
        <SingleSensor sensor={selectedSensor} devices={props.devices} from={_.get(props, `panel.stage_${currentTab}_start`) || _.get(props, `panel.stage_${currentTab > 1 ? currentTab - 1 : 1}_end`)} to={_.get(props, `panel.stage_${currentTab}_end`) || (new Date()).toISOString()} />
      </Modal>

      <Tabs defaultActiveKey={`${_.get(props, 'panel.current_stage')}`} className={style.stages} type="card" tabBarExtraContent={statistics} onChange={setCurrentTab}>
        <TabPane
          tab={<Fragment>Stage 1 (Clay & Wire Test) | {props.panel.stage_1_end && (<Countdown onComplete={() => setTimeout(updateData, TIME_UNTIL_UPDATE)} date={props.panel.stage_1_end}><Fragment>{successStage1 || 0}/{stage0SensorsIds.length}</Fragment></Countdown>)}</Fragment>}
          key="1"
          >
          <Stage1
            result={props.stage1 || []}
            sensors={props.sensors.filter(sensor => !sensor.disconnect)}
            devices={props.devices}
            panel={props.panel}
            onStart={() => props.startStage(panelId, 1).then(() => props.saveStageSensors(panelId, 0, _.map(props.sensors.filter(sensor => !sensor.disconnect), 'id').join(',')))}
            onStop={() => props.stopStage(panelId, 1).then(() => props.saveStageSensors(panelId, 0, ''))}
            isActionable={(props.panel.stage_2_start && Date.now() < (new Date(props.panel.stage_2_end).getTime())) || !props.panel.restart_lock}
            isActive={props.panel.stage_1_start && Date.now() < (new Date(props.panel.stage_1_end).getTime())}
            isSelectable={!props.panel.stage_2_start && props.panel.stage_1_end && Date.now() > (new Date(props.panel.stage_1_end).getTime())}
            onSelectSensors={sensors => props.saveStageSensors(panelId, 1, sensors.join(','))}
            selectedSensorKeys={stage1SensorsIds}
            selectSensor={(sensor) => {
              setSelectedSensor(sensor);
              props.pollSensorsStop();
            }}
          />
        </TabPane>
        <TabPane
          tab={<Fragment>Stage 2 (Sensor Test) | {props.panel.stage_2_end && (<Countdown onComplete={() => setTimeout(updateData, TIME_UNTIL_UPDATE)} date={(new Date(props.panel.stage_2_end)).getTime()}><Fragment>{successStage2 || 0}/{stage1SensorsIds.length}</Fragment></Countdown>)}</Fragment>}
          key="2"
          disabled={!props.panel.stage_1_end || Date.now() < (new Date(props.panel.stage_1_end).getTime())}
        >
          <Stage2
            result={props.stage2 || []}
            sensors={props.sensors.filter(sensor => !sensor.disconnect && !_.isEmpty(sensor) && stage1SensorsIds.includes(sensor.id))}
            devices={props.devices}
            panel={props.panel}
            onStart={() => props.startStage(panelId, 2)}
            onStop={() => props.stopStage(panelId, 2)}
            isActionable={(props.panel.stage_2_enforce_glued && !(props.panel.stage_2_glued_sensors && props.sensors.length === props.panel.stage_2_glued_sensors.split(',').length)) || !props.panel.restart_lock}
            isActive={props.panel.stage_2_start && Date.now() < (new Date(props.panel.stage_2_end).getTime())}
            isSelectable={_.isEmpty(props.panel.stage_3_start)}
            onSelectSensors={sensors => props.saveStageSensors(panelId, 2, sensors.join(','))}
            selectedSensorKeys={stage2SensorsIds}
            selectSensor={(sensor) => {
              setSelectedSensor(sensor);
              props.pollSensorsStop();
            }}
            glueSensor={props.glueSensor}
          />
        </TabPane>
        <TabPane tab={`Stage 3 (Pairing) | ${successStage3}/${stage2SensorsIds.length}`} key="3" disabled={!props.panel.stage_2_end || Date.now() < (new Date(props.panel.stage_2_end).getTime())}>
          <Stage3
            sensors={hideDisconnectedStage3Sensors(props.sensors, props.panel)}
            devices={props.devices}
            panel={props.panel}
            isActionable={!props.panel.restart_lock}
            selectSensor={(sensor) => {
              setSelectedSensor(sensor);
              props.pollSensorsStop();
            }}
            onStart={async () => {
              await props.pairSensors(panelId, {
                stage1: _.map(props.sensors, 'stage1').filter(t => !t.isEmpty), 
                stage2: _.map(props.sensors, 'stage2').filter(t => !t.isEmpty)
              });
            }}
            onStop={() => props.stopStage(panelId, 1)}
            unlockPanel={() => props.unlockPanel(panelId)}
            setBatchNumber={batchNumber => props.setBatchNumber(panelId, batchNumber)}
            isActive={false}
          />
        </TabPane>
      </Tabs>
    </Fragment>
  );
}

export default Main;
