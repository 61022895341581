import _ from 'lodash';

export const byTimeFrame = (fullDuration, testDuration, start, stageResults) => {

  if(!_.isNumber(fullDuration) || !_.isNumber(testDuration) || !_.isString(start)) {
    return stageResults;
  }
  const offsetMs = (fullDuration - testDuration)*60*1000;
  const from = new Date((new Date(start)).getTime() + offsetMs);

  return _.map(stageResults, result => _.filter(result, value => {
    return (new Date(value.time)).getTime() > from.getTime();
  }));
}

const clayTest = (results, panel) => {
  if(!results || _.isEmpty(results)) return null;
  const rtResults = _.filter(results, r => _.get(r, 'rt_min') && _.get(r, 'rt_max'));
  if(_.isEmpty(rtResults)) {
    return null;
  }

  const min = _.isEmpty(results) ? -Infinity : _.minBy(results, 'rt_min').rt_min;
  const max = _.isEmpty(results) ? Infinity : _.maxBy(results, 'rt_max').rt_max;
  
  return max < panel.pressure_max && min > panel.pressure_min
}

const cableTest = (results, pressureRange, temperatureRange, humidityRange) => {
  if(!results || _.isEmpty(results)) return null;

  const issues = new Set();
  for (let i = 0; i < results.length; i++) {
    const result = results[i];
    
    const pressureGapMin = result.rt_min - result.rt_mean;
    const pressureGapMax = result.rt_max - result.rt_mean;

    if(pressureGapMin < pressureRange[0] || pressureGapMax > pressureRange[1]) {
      issues.add('RT Raw');
    }

    const temperatureGapMin = result.tempg_min - result.tempg_mean;
    const temperatureGapMax = result.tempg_max - result.tempg_mean;

    if(temperatureGapMin < temperatureRange[0] || temperatureGapMax > temperatureRange[1]) {
      issues.add('Temperature');
    }

    const humidityGapMin = result.humidity_min - result.humidity_mean;
    const humidityGapMax = result.humidity_max - result.humidity_mean;

    if(humidityGapMin < humidityRange[0] || humidityGapMax > humidityRange[1]) {
      issues.add('Humidity');
    }
  }
 
  if(issues.size > 0) {
    let issuesString = '';
    issues.forEach(issue => {
      issuesString += `${issue},`;
    });

    return issuesString;
  }
  
  return '';
}

const stabilityTest = (results, intervalFactor, totalFactor) => {
  if(!results || _.isEmpty(results)) return null;
  
  for (let i = 0; i < results.length-1; i++) {
    const result = results[i];
    const nextResult = _.find((res, j) => j>i && _.has(res, 'rt_mean'));
    
    if(!nextResult) {
      return true;
    }

    const pressure = result.rt_mean;
    const nextPressure = nextResult.rt_mean;

    const pressureRelations = nextPressure-pressure;
    
    if(pressureRelations < intervalFactor[0] || pressureRelations > intervalFactor[1]) {
      return false;
    }
  }

  const firstPressure = _.get(_.first(results),'rt_mean');
  const lastPressure = _.get(_.last(results),'rt_mean');
  const totalPressureRelation = lastPressure-firstPressure;
  
  if(totalPressureRelation < totalFactor[0] || totalPressureRelation > totalFactor[1]) {
    return false;
  }

  return true;
}

export const addTests = (sensors, panel, stage1 = [], stage2 = []) => {
  if(_.isEmpty(sensors) || _.isEmpty(panel)) {
    return sensors;
  }

  return _.map(sensors, (sensor, i) => {
    const stage2Sensors = _.isEmpty(panel.stage_1_selected_sensors) ? [] : panel.stage_1_selected_sensors.split(',');
    
    return {
      ...sensor,
      result1: stage1[i],
      result2: stage2[i],
      disconnect: sensor.disconnect || !panel.devices.split(',').includes(sensor.device_id),
      stage1: {
        isEmpty: _.isEmpty(stage1[i]),
        clayTest: clayTest(stage1[i], panel),
        cableTest: cableTest(stage1[i],
          [panel.pressure_noise_min, panel.pressure_noise_max],
          [panel.temperature_noise_min, panel.temperature_noise_max],
          [panel.humidity_noise_min, panel.humidity_noise_max]),
        hasAddr: sensor.setAddr,
          get valid() {
            return this.clayTest && _.isEmpty(this.cableTest) && this.hasAddr
          }
      },
      stage2: {
        isEmpty: _.isEmpty(stage2[stage2Sensors.indexOf(sensor.id)]),
        clayTest: clayTest(stage2[stage2Sensors.indexOf(sensor.id)], panel),
        cableTest: cableTest(stage2[stage2Sensors.indexOf(sensor.id)],
          [panel.pressure_noise_min, panel.pressure_noise_max],
          [panel.temperature_noise_min, panel.temperature_noise_max],
          [panel.humidity_noise_min, panel.humidity_noise_max]),
        stabilityTest: stabilityTest(stage2[stage2Sensors.indexOf(sensor.id)],
          [panel.stability_trend_min, panel.stability_trend_max],
          [panel.stability_trend_min, panel.stability_trend_max]),
          get valid() {
            return this.clayTest && _.isEmpty(this.cableTest) && this.stabilityTest
          }
      }
    }
    });
}