import React, { Fragment } from 'react';
import _ from 'lodash';
import style from './Stage3.module.css';
import Switch from 'react-switch-m';
import { PlayCircleFilled, StopFilled, DownloadOutlined } from '@ant-design/icons';
import FullTable from './FullTable';
import { CSVLink } from 'react-csv';
import { Button, InputNumber, Modal } from 'antd';
import * as XLSX from 'xlsx';

function pairSensors(sensors, panel) {
  const toPair = _(sensors).sortBy(s => s.rt).reverse().value();
  const pairRange = panel.stage_3_pair_range || 30;

  let j = 1;
  for (let i = 0; i < toPair.length; i++) {
    const sensor = toPair[i];
    let x = i+1;
    let nextSensor = toPair[x];
    
    while(nextSensor && nextSensor.pair > 0) {
      nextSensor = toPair[++x];
    }

    if(!nextSensor) {
      break;
    }

    if(sensor.pair > 0) {
      continue;
    }

    if(Math.abs(sensor.calibrationValue - nextSensor.calibrationValue) <= pairRange) {
      sensor.pair = j;
      nextSensor.pair = j;
      j++;
      i++; // jump to next sensor
      continue;
    }
  }

  return toPair;
}

function Stage3(props) {
  const [modal, contextHolder] = Modal.useModal();

  const csvData = _.orderBy(_.map(props.sensors, (sensor) => {
    const order = _.isEmpty(props.panel.stage_3_sensors_order) ? -1 : props.panel.stage_3_sensors_order.split(',').indexOf(sensor.id);

    return {
      id: sensor.id,
      device: _.get(_.find(props.devices, {id: sensor.device_id}), 'name') || sensor.device_id,
      port: sensor.port,
      raw: sensor.rt,
      temperature: sensor.tempg,
      pair: Math.floor((order/2)+1),
    }
  }), 'pair');
  
  const stage3SensorsIds = _.get(props, 'panel.stage_3_sensors_order') ? _.get(props, 'panel.stage_3_sensors_order').split(',') : [];
  const successStage3 = _.isEmpty(stage3SensorsIds) ? 0 : stage3SensorsIds.length;
  const stage2SensorsIds = _.get(props, 'panel.stage_2_selected_sensors') ? _.get(props, 'panel.stage_2_selected_sensors').split(',') : [];


  const exampleDownload = () => {
	  const fileName = `sensors_labels_${Date.now()}.xls`;

    const dataWs = XLSX.utils.json_to_sheet(_.map(props.sensors, (sensor, i) => ({
      SN: i,
      'A Side - First Line': 'RootTens',
      'A Side - Second Line': _.get(props, 'panel.batch_number'),
      'B Side': sensor.id,
      'B Side QR': sensor.id,
      'Flag Area Text': `${_.get(_.find(props.devices, {id: sensor.device_id}), 'name').split('Calibration_')[1]}.${sensor.port}`
    })));
    const settingsWs = XLSX.utils.aoa_to_sheet([
      ["Template Version:", 8, "You can't change the value of Template Version"],
      ["Font Type:", "Helvetica", ""],
      ["Font Style:", "Bold", ""],
      ["Font Size: A Side - First Line", 10, "unit: pt, Max 30pt, Min 6pt"],
      ["Font Size: A Side - Second Line", 10, ""],
      ["Font Size: B Side", 14, ""],
      ["Font Size: Flag Area Text", 10, ""]
    ]);
    const howWs = XLSX.utils.aoa_to_sheet([
      ["Please don't change the Excel Sheets sequences."],
      ["Please don't delete any Excel sheets"],
      ["Remember that you can change the font size,font type in the \"Setting\" sheet"],
      [],
      ["Thanks!"],
      ["We believe our customer’s feedback and review have crucial importance to our product innovation.\nThanks to some of the great reviewer at Amazon. Their names are: Pacaw 66, Luis, Salsaman, KITT Eire.  We learn lots from you!"],
      [],
      ["Email: services@mr-label.com"]
    ])
    const wb = XLSX.utils.book_new();
    
		XLSX.utils.book_append_sheet(wb, dataWs, 'DATA');
		XLSX.utils.book_append_sheet(wb, settingsWs, 'Settings');
		XLSX.utils.book_append_sheet(wb, howWs, 'How');

		XLSX.writeFile(wb, fileName, {bookType: 'xls'});
  }
  const debounceSetBatchNumber = _.debounce(props.setBatchNumber, 500);

  return (
    <Fragment>
      <div className={style.panel}>
        <div className={style.actions}>
          <Button onClick={exampleDownload}>Download Excel</Button>
          <CSVLink className={style.downloadButton} data={csvData} filename={`stage3-${Date.now()}.csv`}><DownloadOutlined /></CSVLink>
          <div className={style.statistic}>
            Sensors: {stage2SensorsIds.length} | Paired: {successStage3} | unPaired {stage2SensorsIds.length - successStage3}
          </div>
        </div>
        <div className={style.rightMenu}>
          <span>
            Batch Number:
            <InputNumber max="600" min="202" defaultValue={props.panel.batch_number} onChange={(value) => debounceSetBatchNumber(value)} />
          </span>
          <span className={style.unlockButton}>
            Unlock:
            <Switch
              disabled={!props.panel.restart_lock}
              width={80}
              height={40}
              offColor="#DDD"
              onColor="#DDD"
              checked={props.panel.restart_lock}
              className={style.nextButton}
              checkedIcon={<PlayCircleFilled />}
              uncheckedIcon={<StopFilled />}
              disableTouch={true}
              onChange={(valid) => {
                if(!valid) {
                  props.unlockPanel();
                }
              }}
            />
          </span>
          <Switch
            disabled={!props.isActionable}
            width={80}
            height={40}
            offColor="#ff004b"
            onColor="#4bff00"
            checked={props.isActive || false}
            className={style.nextButton}
            checkedIcon={<StopFilled />}
            uncheckedIcon={<PlayCircleFilled />}
            disableTouch={true}
            onChange={(valid) => {
              if(!props.isActionable) {
                return;
              }

              if(valid) {
                modal.confirm({title: '', content: (
                  <div>
                    you are about to start Stage 3 <br />
                    Are you sure?
                  </div>), onOk: () => props.onStart(), autoFocusButton: 'cancel'});
              } else {
                modal.confirm({title: '', content: (
                  <div>
                    you are about to stop Stage 3<br />
                    You will have to restart the entire stage after approve<br />
                    Are you sure?
                  </div>), onOk: () => props.onStop(), autoFocusButton: 'cancel'});
              }
            }}
          />
        </div>
      </div>
      <FullTable dataSource={pairSensors(props.sensors, props.panel)} {...props} />
      {contextHolder}
    </Fragment>
  )
}

export default Stage3;