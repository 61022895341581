import _ from 'lodash';
import moment from 'moment';

export default (raw, temperatures, rawTc, humidity, calibrationValue = 0) => {
  
  return {
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {

        return `
          <div>${moment(params[0].axisValue).format('YYYY/MM/DD H:mm:ss')}<div>
          <div>
          ${_.map(params, p => `<i style="background:${p.color}; width: 10px; height: 10px; display:inline-block;"></i>${p.seriesName}: ${_.isArray(p.value) && p.value[1] ? p.value[1] : '-'}`).join('</div><div>')}
          </div>
        `;          
      },
      axisPointer: {
          animation: false
      }
    },
    legend: {
        data: ['Raw', 'Raw Tc', 'Temperature', 'Humidity'],
        top: '90%',
        left: '5%'
    },
    toolbox: {
        feature: {
            dataZoom: {
              title: 'Zoom'
            },
            restore: {
              title: 'Restore'
            },
            saveAsImage: {
              title: 'Save As Image'
            }
        }
    },
    axisPointer: {
        link: {xAxisIndex: 'all'}
    },
    dataZoom: [
        {
          type: 'slider',
          show: true,
          realtime: true,
          start: 0,
          end: 100,
          xAxisIndex: [0, 1, 2],
          top: '85%'
        },
        {
          type: 'inside',
          realtime: true,
          start: 0,
          end: 100,
          xAxisIndex: [0, 1, 2]
        }
    ],
    grid: [{
      top: '6%',
      height: '20%',
      left: '5%'
    }, {
        top: '33%',
        height: '20%',
        left: '5%'
    }, {
      top: '60%',
      height: '20%',
      left: '5%'
  }],
    xAxis: [
      {
        show: false,
        gridIndex: 0,
        type: 'time',
        axisLine: {
          show: false
        }
      },{
        show: false,
        gridIndex: 1,
        type: 'time',
        axisLine: {
          show: false
        }
      },{
        gridIndex: 2,
        type: 'time',
        axisLine: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        gridIndex: 0,
        name: 'Raw',
        type: 'value',
      },{
        gridIndex: 1,
        name: 'Temperature',
        type: 'value',
      },{
        gridIndex: 2,
        name: 'Humidity',
        type: 'value',
        min: 0,
        max: 100
      }
    ],
    series: [
        {
          name: 'Raw',
          type: 'line',
          symbolSize: 8,
          hoverAnimation: false,
          data: raw,
          markLine: {
            data: [{
              name: 'start',
              yAxis: calibrationValue
            }]
          },
          connectNulls: true,
          showSymbol: false
        },
        {
            name: 'Raw Tc',
            type: 'line',
            xAxisIndex: 0,
            yAxisIndex: 0,
            hoverAnimation: false,
            data: rawTc,
            connectNulls: true,
            showSymbol: false
        },
        {
            name: 'Temperature',
            type: 'line',
            xAxisIndex: 1,
            yAxisIndex: 1,
            hoverAnimation: false,
            data: temperatures,
            connectNulls: true,
            showSymbol: false
        },
        {
            name: 'Humidity',
            type: 'line',
            xAxisIndex: 2,
            yAxisIndex: 2,
            hoverAnimation: false,
            data: humidity,
            connectNulls: true,
            showSymbol: false
        }
    ]
  };  
}