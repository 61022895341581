import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import { Modal, Form, InputNumber, Checkbox, Card, Button, Select } from 'antd';
import update_panel from './update_panel';

import style from './Admin.module.css';
import { useParams } from 'react-router-dom';
import get_devices from './get_devices';

function Admin(props) {
  const { panel, getPanel } = props;
  const [modal, contextHolder] = Modal.useModal();
  const [config, setConfig] = useState({devices: []});
  const [devices, setDevices] = useState([]);
  const params = useParams();
  const panelId = params.panelId || 1;
  const userDetails = JSON.parse(window.atob(window.localStorage.getItem('token').split('.')[1]));

  if(userDetails.orgUnitPath !== '/backoffice-admin') {
    window.location = '/login'
  }

  const updateConfig = () => {
    if(_.isEqual(panel.devices, config.devices.join(','))) {
      update_panel(parseInt(panelId) || 1, config).then(() => alert('updated'));
    } else {
      modal.confirm({
        title: '',
        content: (
        <div>
          Changing the devices will reset the entire process!
        </div>),
        onOk: () => update_panel(parseInt(panelId) || 1, config).then(() => props.stopStage(parseInt(panelId) || 1, 1)).then(() => alert('updated')),
        autoFocusButton: 'cancel'
      });
    }
  }

  useEffect(() => {
    getPanel(panelId || 1)
    get_devices().then(({devices}) => setDevices(devices));
  }, [getPanel, panelId]);

  useEffect(() => {
    setConfig({
      ..._.pick(panel, [
      'stage_1_duration_minutes',
      'stage_1_tests_duration_minutes',
      'stage_1_moving_averages_minutes',
      'stage_2_duration_minutes',
      'stage_2_tests_duration_minutes',
      'stage_2_moving_averages_minutes',
      'auto_pass_stage_1',
      'stage_2_enforce_glued',
      'auto_pass_stage_2',
      'dry_max',
      'pressure_min',
      'pressure_max',
      'pressure_noise_min',
      'pressure_noise_max',
      'temperature_noise_min',
      'temperature_noise_max',
      'humidity_noise_min',
      'humidity_noise_max',
      'stability_trend_min',
      'stability_trend_max',
      'stage_3_pair_range',
    ]), devices: panel.devices ? panel.devices.split(','): []})
  }, [panel])

  return (
    <Fragment>
      <Card className={style.adminCard}>
        <h1>Admin Configurations</h1>
        <Form>
          <div>devices: 
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Please select"
            onChange={devices => setConfig({...config, devices})}
            value={config.devices || []}
          >
            {_.sortBy(devices.filter(device => _.has(device, 'id')), 'name').map(device => (
              <Select.Option key={device.id}>{device.name || device.id}</Select.Option>
            ))}
          </Select>
            
          </div>
          <div>stage 1 duration (minutes): <InputNumber value={config.stage_1_duration_minutes} onChange={duration => setConfig({...config, stage_1_duration_minutes: duration})}/></div>
          <div>stage 1 tests duration (minutes): <InputNumber value={config.stage_1_tests_duration_minutes} onChange={duration => setConfig({...config, stage_1_tests_duration_minutes: duration})}/></div>
          <div>stage 1 moving averages interval (minutes): <InputNumber value={config.stage_1_moving_averages_minutes} onChange={duration => setConfig({...config, stage_1_moving_averages_minutes: duration})}/></div>
          <div>stage 2 duration (minutes): <InputNumber value={config.stage_2_duration_minutes} onChange={duration => setConfig({...config, stage_2_duration_minutes: duration})}/></div>
          <div>stage 2 tests duration (minutes): <InputNumber value={config.stage_2_tests_duration_minutes} onChange={duration => setConfig({...config, stage_2_tests_duration_minutes: duration})}/></div>
          <div>stage 2 enforce glued: <Checkbox checked={config.stage_2_enforce_glued} onChange={checkbox => setConfig({...config, stage_2_enforce_glued: checkbox.target.checked})}/></div>
          <div>stage 2 moving averages interval (minutes): <InputNumber value={config.stage_2_moving_averages_minutes} onChange={duration => setConfig({...config, stage_2_moving_averages_minutes: duration})}/></div>
          <div>auto pass stage 1: <Checkbox checked={config.auto_pass_stage_1} onChange={checkbox => setConfig({...config, auto_pass_stage_1: checkbox.target.checked})}/></div>
          <div>auto pass stage 2: <Checkbox checked={config.auto_pass_stage_2} onChange={checkbox => setConfig({...config, auto_pass_stage_2: checkbox.target.checked})}/></div>
          <div>pressure min (millibar): <InputNumber value={config.pressure_min} onChange={num => setConfig({...config, pressure_min: num})}/></div>
          <div>pressure max (millibar): <InputNumber value={config.pressure_max} onChange={num => setConfig({...config, pressure_max: num})}/></div>
          <div>max dry (millibar): <InputNumber value={config.dry_max} onChange={num => setConfig({...config, dry_max: num})}/></div>
          <div>pressure noise range (millibar): <InputNumber max={0} value={config.pressure_noise_min} onChange={num => setConfig({...config, pressure_noise_min: num})} /> - <InputNumber min={0} value={config.pressure_noise_max} onChange={num => setConfig({...config, pressure_noise_max: num})}/></div>
          <div>temperature noise range (celsius): <InputNumber max={0} value={config.temperature_noise_min} onChange={num => setConfig({...config, temperature_noise_min: num})} /> - <InputNumber min={0} value={config.temperature_noise_max} onChange={num => setConfig({...config, temperature_noise_max: num})}/></div>
          <div>humidity noise range (percentage): <InputNumber max={0} value={config.humidity_noise_min} onChange={num => setConfig({...config, humidity_noise_min: num})} /> - <InputNumber min={0} value={config.humidity_noise_max} onChange={num => setConfig({...config, humidity_noise_max: num})}/></div>
          <div>stability trend (percentage): <InputNumber max={0} value={config.stability_trend_min} onChange={num => setConfig({...config, stability_trend_min: num})} /> - <InputNumber min={0} value={config.stability_trend_max} onChange={num => setConfig({...config, stability_trend_max: num})}/></div>
          <div>pair range: <InputNumber min={0} value={config.stage_3_pair_range} onChange={num => setConfig({...config, stage_3_pair_range: num})} /></div>

          <div><Button onClick={updateConfig}>Save</Button></div>
        </Form>
      </Card>
      {contextHolder}
    </Fragment>
  );
}

export default Admin;
