import React, { useEffect, useState } from 'react';
import get_device_shadow from './get_device_shadow';
import _ from 'lodash';
import DeviceSingleSensor from './DeviceSingleSensor';
import { useParams } from 'react-router-dom';

function SingleDevice() {
  const params = useParams();
  const [device, setDevice] = useState({});

  useEffect(() => {
    get_device_shadow(params.deviceId).then(({device}) => setDevice(device));
  }, [params]);

  const rawSensors = _.isEmpty(device) ? [] : _.pickBy(device, (variable, label) => /^rt\d_raw$/.test(label) || /^rt\d$/.test(label));

  let sensors = _.map(rawSensors, (rt, property) => {
    const r = /\d+/;
    const port = parseInt(_.first(property.match(r)));

    return {port};
  });

  return (
    <div>
      {_.map(sensors, (sensor) => (
        <DeviceSingleSensor key={sensor.port} sensorPort={sensor.port} device={device} />
      ))}
    </div>
  )
}



export default SingleDevice;