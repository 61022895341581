import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import get_devices from '../Admin/get_devices';
import { Select } from 'antd';
import SingleDevice from './SingleDevice';
import { Route, Router, Switch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

function Device() {
  const history = useHistory();
  const [devices, setDevices] = useState([]);
  const setSelectedDevice = (id) => {
    history.push(`/backoffice/device/${id}`);
  }

  useEffect(() => {
    get_devices().then(({devices}) => setDevices(devices));
  }, []);

  return (
    <div>
      <Select
        style={{width: '100%'}}
        showSearch
        placeholder="Device"
        onChange={setSelectedDevice}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {devices.filter(device => _.has(device, 'id')).map(device => (
          <Select.Option key={device.id}>{device.name || device.id}</Select.Option>
        ))}
      </Select>

      <Router history={history} basename="/backoffice/device">
        <Switch>
          <Route path='/backoffice/device/:deviceId'>
            <SingleDevice devices={devices} />
          </Route>
        </Switch>
      </Router>
        
    </div>
  )
}

export default Device;