import _ from 'lodash';
import { connect } from 'react-redux';
import { getPanel, startStage, stopStage, saveStageSensors } from '../actions/panels';
import { pollSensorsStart, pollSensorsStop, glueSensor } from '../actions/sensors';
import { byTimeFrame, addTests } from '../actions/state_test';
import Router from '../Routes';

const mapStateToProps = state => {
  const stage1 = byTimeFrame(
    _.get(state.panel, 'panel.stage_1_duration_minutes'),
    _.get(state.panel, 'panel.stage_1_tests_duration_minutes'),
    _.get(state.panel, 'panel.stage_1_start'),
    _.get(state.panel, 'stage1'),
  );
  const stage2 = byTimeFrame(
    _.get(state.panel, 'panel.stage_2_duration_minutes'),
    _.get(state.panel, 'panel.stage_2_tests_duration_minutes'),
    _.get(state.panel, 'panel.stage_2_start'),
    _.get(state.panel, 'stage2'),
  );

  return {
    ...state.panel,
    sensors: addTests(
      _.get(state.panel, 'sensors'),
      _.get(state.panel, 'panel'),
      stage1,
      stage2,
      ),
    stage1,
    stage2,
  }
}

const mapDispatchToProps = dispatch => ({
  getPanel: dispatch(getPanel),
  pollSensorsStart: dispatch(pollSensorsStart),
  pollSensorsStop: dispatch(pollSensorsStop),
  startStage: dispatch(startStage),
  stopStage: dispatch(stopStage),
  saveStageSensors: dispatch(saveStageSensors),
  glueSensor: dispatch(glueSensor),
})

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(Router);

