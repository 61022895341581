import config from '../../config';

function get_sensor_data(deviceId, port, from, to) {
  const token = window.localStorage.getItem('token'); 
  if(!deviceId || !port) {
    return Promise.resolve({});
  }

  return fetch(
    `${config.server.url}/device/${deviceId}/sensor/${port}?from=${from}&to=${to}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorize': token
      }
    }
  )
  .then(res => res.json())
  .then(payload => {
    if(payload.error && 
      (
        payload.error.includes('Invalid Credentials') ||
        payload.error.includes('Login Required')
      )){
        window.location = '/login';
      }

    return payload;
  });
}

export default get_sensor_data;