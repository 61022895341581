import _ from 'lodash';
import config from '../../../config';

function get_sensors_details(sensorsIds) {
  if(_.isEmpty(sensorsIds)) {
    return Promise.resolve({});
  }
  const token = window.localStorage.getItem('token'); 

  return fetch(
    `${config.server.url}/sensors/calibration?sensorsIds=${sensorsIds.join(',')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorize': token
      }
    }
  ).then(res => res.json())
  .then(payload => {
    if(payload.error && 
      (
        payload.error.includes('Invalid Credentials') ||
        payload.error.includes('Login Required')
      )){
        window.location = '/login';
      }

    return payload;
  });
}

export default get_sensors_details;