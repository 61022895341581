import React, { Fragment } from 'react';
import _ from 'lodash';
import Switch from 'react-switch-m';
import { PlayCircleFilled, StopFilled } from '@ant-design/icons';

import style from './Stage1.module.css';
import FullTable from './FullTable';
import EmptyTable from './EmptyTable';
import Countdown from 'react-countdown';
import { Modal } from 'antd';

function Stage1(props) {
  const [modal, contextHolder] = Modal.useModal();
  const isDone = props.panel.stage_1_end && Date.now() > (new Date(props.panel.stage_1_end).getTime());
  const allSensorsIds = _.get(props, 'panel.stage_0_selected_sensors') ? _.get(props, 'panel.stage_0_selected_sensors').split(',') : [];
  const stage1SensorsIds = _.get(props, 'panel.stage_1_selected_sensors') ? _.get(props, 'panel.stage_1_selected_sensors').split(',') : [];
  const successStage1 = stage1SensorsIds.length || _.filter(props.sensors, sensor => _.get(sensor,'stage1.valid')).length;
  
  const CountdownComponent = (
    <div className={style.statistic}>
      <Countdown date={props.panel.stage_1_end || Date.now()}>
        <Fragment>Sensor: {allSensorsIds.length} | Success: {successStage1 || 0} | Failed: {successStage1 ? allSensorsIds.length-successStage1 : allSensorsIds.length}</Fragment>
      </Countdown>
    </div>
  )

  return (
    <Fragment>
      <div className={style.panel}>
        <div className={style.actions}>
          {props.panel.stage_1_end && CountdownComponent}
        </div>
        <div>
          <Switch
            disabled={!props.isActionable}
            disableTouch={true}
            width={80}
            height={40}
            offColor="#ff004b"
            onColor="#4bff00"
            checked={props.isActive || false}
            className={style.nextButton}
            checkedIcon={<StopFilled />}
            uncheckedIcon={<PlayCircleFilled />}
            onChange={(valid) => {
              if(!props.isActionable) {
                return;
              }
              if(valid) {
                modal.confirm({title: '', content: (
                  <div>
                    you are about to start Stage 1 <br />
                    It will delete all progress done so far on these sensors <br />
                    Are you sure?
                  </div>), onOk: ()=>props.onStart(), autoFocusButton: 'cancel'});
              } else {
                modal.confirm({title: '', content: (
                  <div>
                    you are about to stop Stage 1 <br />
                    You will have to restart the entire stage after approve <br />
                    Are you sure?

                  </div>), onOk: ()=> props.onStop(), autoFocusButton: 'cancel'});
              }
            }}
          />
        </div>
      </div>
      {isDone ? <FullTable
        dataSource={props.sensors}
        {...props}
      /> : <EmptyTable
      dataSource={props.sensors}
      {...props}
      />}
      {contextHolder}
    </Fragment>
  )
}

export default Stage1;