import React from 'react';
import _ from 'lodash';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import Login from './pages/Login/Login';
import Device from './pages/Backoffice/Device';
import Sensor from './pages/Backoffice/Sensor';
import PanelView from './containers/PanelView';
import AdminView from './containers/AdminView';
import Backoffice from './pages/Backoffice/Backoffice';
import CalibrationAdjustment from './pages/Backoffice/CalibrationAdjustment/CalibrationAdjustment';

function WithRouter(props) {

  return (
    <Route path={props.path}>
      {_.isEmpty(window.localStorage.getItem('token')) && <Redirect
        to={{
          pathname: '/login'
        }}
      />}
      <props.page />
    </Route>
  )
}

export default function Routes(props) {
  if(_.has(props, 'error.message') && 
  _.isString(props.error.message) && 
  ( // token related errors
    props.error.message.includes('Invalid Credentials') ||
    props.error.message.includes('Login Required') ||
    props.error.message.includes('jwt malformed') ||
    props.error.message.includes('No refresh token is set.') ||
    props.error.message.includes('Resource Not Found: userKey') ||
    props.error.message.includes('Cannot read property \'access_token\' of undefined')
  )) {
    window.localStorage.clear();
    window.location = '/login'
  }

  return (
    <Router>
      <Switch>
        <Route path='/login'>
          <Login />
        </Route>
        <WithRouter path='/admin/:panelId' page={AdminView} />
        <WithRouter path='/admin' page={AdminView} />
        <WithRouter path='/main/:panelId' page={PanelView} />
        <WithRouter path='/main' page={PanelView} />
        <WithRouter exact path='/backoffice' page={Backoffice} />
        <WithRouter path='/backoffice/device' page={Device} />
        <WithRouter path='/backoffice/sensor' page={Sensor} />
        <WithRouter path='/backoffice/calibration-adjustment' page={CalibrationAdjustment} />
        <Route path="*">
          <Redirect to='/backoffice' />
        </Route>
      </Switch>
    </Router>
  );
}