import React, { useState } from 'react';
import _ from 'lodash';
import SingleSensor from '../Main/SingleSensor/SingleSensor';
import moment from 'moment';
import { DatePicker, Modal, Input, Button } from 'antd';
import update_calibration_value from './update_calibration_value';
import { ReloadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

function SensorSingleSensor(props) {
  const params = useParams();
  const [isEditCalibration, setIsEditCalibration] = useState(false);
  const [sensorDetails, setSensorDetails] = useState({});
  const [newCalibrationValue, setNewCalibrationValue] = useState(0);
  const [newCalibrationTemp, setNewCalibrationTemp] = useState(0);
  const [from, setFrom] = useState(moment().subtract(7,'d').toISOString());
  const [to, setTo] = useState(moment().toISOString());
  const {port, device_id: deviceId} = _.find(props.sensors, selectedSensor => selectedSensor.id === params.sensorId) || {port: 0, device_id: 0}

  return (
    <div> 
    <DatePicker.RangePicker
      defaultValue={[moment().subtract(7,'d'), moment()]}
      ranges={{
        Today: [moment().startOf('day'), moment()],
        'Last 3 days': [moment().subtract(3, 'days'), moment()],
        'Last Week': [moment().subtract(1, 'week'), moment()],
        'Last Month': [moment().subtract(1, 'month'), moment()],
        'This Month': [moment().startOf('month'), moment()],
        'All': [moment().subtract(20, 'years'), moment()]
      }}
      onChange={(dates) => {
        if(!dates) {
          return;
        }
        const [fromDatePicker, toDatePicker] = dates;
        setFrom(fromDatePicker.toISOString());
        setTo(toDatePicker.toISOString());
      }}
    />
      <Button onClick={() => setTo(moment().toISOString())}><ReloadOutlined /></Button>
      <SingleSensor onCalibrationClick={(oldValue, oldTemp) => {
        setIsEditCalibration(true);
        setSensorDetails({id: params.sensorId, oldValue, oldTemp});
      }} sensor={{id:params.sensorId, deviceId, port}}
      devices={props.devices}
      from={from}
      to={to}
      />
      
      <Modal
        visible={isEditCalibration}
        onCancel={() => {
          setIsEditCalibration(false)
        }}
        centered={true}
        footer={[]}
        width="auto"
        className="sensor_data_modal"
      >
        You are about to edit sensor {sensorDetails.id} calibration value from {sensorDetails.oldValue || 'NaN'} to: <br /> Max: <Input style={{width: '100px'}} onChange={e => setNewCalibrationValue(e.target.value)} type="number" /> Temp: <Input onChange={e => setNewCalibrationTemp(e.target.value)} type="number"  style={{width: '100px'}} />
        <br />
        are you sure <Button onClick={async () => {
          await update_calibration_value(sensorDetails.id, newCalibrationValue, newCalibrationTemp);
          setNewCalibrationValue();
          setIsEditCalibration(false);
        }}>OK</Button>
      </Modal>

    </div>
  )
}

SensorSingleSensor.defaultProps = {
  device: {id: null}
}

export default SensorSingleSensor;