import config from '../config';

let timer = null;

const getSensors = id => dispatch => {
  const token = window.localStorage.getItem('token'); 
  dispatch({type: 'LOAD_SENSORS'});
  return fetch(
    `${config.server.url}/panel/${id}/sensors`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorize': token
      }
    }
  ).then(res => res.json())
  .then(payload => {
    if(payload.error) {
      throw new Error(payload.error);
    }
    
    return payload;
  })
  .then(({sensors}) => dispatch({type: 'SET_SENSORS', payload: sensors}))
  .catch(error => dispatch({type: 'ERROR', error}));
}

export const pollSensorsStart = (dispatch) => (id) => {
  clearInterval(timer);
  timer = setInterval(() => dispatch(getSensors(id)), 60000);
  dispatch({ type: 'POLL_SENSORS_START' });
}

export const pollSensorsStop = (dispatch) => () => {
  clearInterval(timer);
  dispatch({ type: 'POLL_SENSORS_STOP' });
}

export const glueSensor = (dispatch) => (panelId, sensorId) => {
  const token = window.localStorage.getItem('token'); 

  return fetch(
    `${config.server.url}/panel/${panelId}/glue/${sensorId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorize': token
      }
    }
  ).then(res => res.json())
  .then(payload => {
    if(payload.error) {
      throw new Error(payload.error);
    }
    
    return payload;
  })
  .then(({panel}) => dispatch({type: 'SET_PANEL_DETAILS', payload: panel}))
  .catch(error => dispatch({type: 'ERROR', error}));
}
