import React from 'react';
import _ from 'lodash';
import { Table, Button } from 'antd';

import style from './Stage3.module.css';


function FullTable(props) {
  const columns = [{
    title: 'Sys. Name',
    dataIndex: 'device_id',
    key: 'deviceId',
    render: (deviceId, record) => {
      const device = _.find(props.devices, {id: deviceId});

      return (<Button type="link" style={{color: "black"}} onClick={() => props.selectSensor(record)}>{_.get(device, 'name') || 'Unknown'}</Button>)
    },
    sorter: {
      multiple: 1,
      compare: (a, b) => {
        const deviceA = _.find(props.devices, {id: a.device_id}) || {name: 'Unknown'};
        const deviceB = _.find(props.devices, {id: b.device_id}) || {name: 'Unknown'};

        const aName = deviceA.name;
        const bName = deviceB.name;

        return aName.localeCompare(bName);
      }
    },
    filters: _.map(_.sortBy(props.devices, 'name'), device => ({text: device.name || device.id, value: device.id})),
    onFilter: (value, record) => {
      return record.device_id === value;
    }
  },{
      title: 'Port',
      dataIndex: 'port',
      key: 'port',
      filters: '12345678'.split('').map(port => ({text: port, value: parseInt(port)})),
      onFilter: (value, record) => {
        return record.port === value;
      },
      sorter: {
        multiple: 2,
        compare: (a, b) => a.port - b.port
      },
      render: (index) => index
    },{
      title: 'Raw/Temperature',
      key: 'pressureTemperature',
      render: (v, record) => `${record.rt}/${record.tempg}`
    },{
      title: 'Calibration',
      key: 'calibration',
      render: (v, record) => !_.isNumber(record.calibrationValue) ? '-/-' : `${record.calibrationValue}/${record.calibrationTemp}`
    }, {
      title: 'Stability',
      dataIndex: 'result',
      key: 'stabilityTest',
      render: v => 'V',
    },{
      title: 'Range',
      dataIndex: 'result',
      key: 'rangeTest',
      render: v => 'V',
    }, {
      title: 'Noise',
      dataIndex: 'result',
      key: 'noiseTest',
      render: v => 'V',
    }, {
      title: 'Order',
      dataIndex: 'id',
      key: 'order',
      render: (id) => {
        if(props.panel.stage_3_sensors_order === null) return '-';
        const order = props.panel.stage_3_sensors_order.split(',');
        let recordOrder = order.indexOf(id);
        
        if(recordOrder === -1) {
          return '-';
        }

        return recordOrder;
      },
      className: style.hideColumn,
      sortOrder: 'ascend',
      sorter: {
        multiple: 3,
        compare: (a, b) => {
          if(props.panel.stage_3_sensors_order === null) return '-';
          const order = props.panel.stage_3_sensors_order.split(',');
          let aRecordOrder = order.indexOf(a.id);
          let bRecordOrder = order.indexOf(b.id);
          
          aRecordOrder = aRecordOrder === -1 ? Infinity : aRecordOrder;
          bRecordOrder = bRecordOrder === -1 ? Infinity : bRecordOrder;

          return aRecordOrder - bRecordOrder;
        }
      }
    }
  ]
  
  return (
    <Table
      className={style.stage3_fulltable}
      dataSource={props.dataSource}
      columns={columns}
      rowKey='id'
      pagination={false}
      showSorterTooltip={false}
      scroll={{y: 'calc(100vh - 162px)'}}
      rowClassName={(record) => {
        if(_.isEmpty(props.panel.stage_3_sensors_order)) return {
          [style.noPair]: true
        }

        const order = props.panel.stage_3_sensors_order.split(',');
        let recordOrder = order.includes(record.id);

        return {
          [style.noPair]: !recordOrder
        };
      }}
    />
  )
}

export default FullTable;