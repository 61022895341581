const panel = (state = {devices: [], panel: {}, sensors:[], stage1: [], stage2: []}, action) => {
  switch (action.type) {
    case 'SET_PANEL':
      return action.payload;
    case 'SET_SENSORS':
      return {...state, sensors: action.payload};
    case 'SET_PANEL_DETAILS':
      return {...state, panel: action.payload};
    default:
      return state
  }
}

export default panel;
