import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import CalibrationAdjustmentSensor from './CalibrationAdjustmentSensor';
import get_sensors_details from './get_sensors_details';
import { Card } from 'antd';
import style from './CalibrationAdjustment.module.css';
import moment from 'moment';

function CalibrationAdjustmentDevice(props) {
  const device = _.find(props.devices, {id: props.deviceId});
  const [sensorsDetails, setSensorsDetails] = useState([]);
  const rawSensors = _.isEmpty(device) ? [] : _.pickBy(device, variable => _.has(variable, 'id'));
  let sensors = _.map(rawSensors, (rt, property) => {
    const r = /\d+/;
    const port = parseInt(_.first(property.match(r)));
    const sensorShadow = _.find(props.sensors, {id: rt.id});

    return {...sensorShadow, id: rt.id, port};
    });
  const getCalibrationValues = () => {
    const deviceRawSensors = _.isEmpty(device) ? [] : _.pickBy(device, variable => _.has(variable, 'id'));
    get_sensors_details(_.map(deviceRawSensors, 'id')).then(({results}) => setSensorsDetails(results));
  }

  useEffect(getCalibrationValues, [device]);
  const colors = ['rgb(244, 115, 115)', 'rgb(55, 214, 122)', 'rgb(244, 206, 115)']


  return (
    <div style={{background: '#F2F2F2', maxWidth: '100%'}}>
      <Card title="Use Carefully!!!" bodyStyle={{padding: '0 5px'}}>
        <p className={style.warning}>Assign RootTens calibration values to device</p>
      </Card>

      <div style={{display: 'flex', flexWrap: 'wrap'}}>
        {_.sortBy(sensors, 'port').map((sensor, i) => (
          <CalibrationAdjustmentSensor onChangeCalibration={() => {getCalibrationValues(); props.onChangeCalibration && props.onChangeCalibration();}} color={colors[i]} sensorDetail={_.filter(sensorsDetails, sensorDetails => sensorDetails.sensor_id === sensor.id)} key={sensor.port} deviceName={_.get(device,'name')} sensor={sensor} />
        ))}
      </div>
      <div>
        {!_.isEmpty(sensorsDetails) && <Card className={style.valuesTableWrapper} title="Values Table">
          <table className={style.valuesTable}>
            <thead>
              <tr>
                <th>DATE</th>
                {_.sortBy(sensors, 'port').map(sensor => (
                  <Fragment key={sensor.id}>
                    <th key={`cal_${sensor.port}`}>CAL_RT{sensor.port}_MAX last value</th>
                    <th key={`temp_${sensor.port}`}>CAL_RT{sensor.port}_TEMP last value</th>
                    <th key={`source_${sensor.port}`}>CAL_RT{sensor.port}_SOURCE last value</th>
                  </Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {_.map(_.sortBy(sensorsDetails, 'time').reverse(), sensorDetails=>(
                <tr key={sensorDetails.time}>
                  <td>
                    {moment(sensorDetails.time).format('DD/MM/YYYY HH:mm')}
                  </td>
                  {_.sortBy(sensors, 'port').map(sensor => (
                    <Fragment key={sensor.id}>
                      <td>
                        {sensor.id ===sensorDetails.sensor_id ? sensorDetails.calibrationValue : '---'}
                      </td>
                      <td>
                        {sensor.id ===sensorDetails.sensor_id ? sensorDetails.calibrationTemp : '---'}
                      </td>
                      <td>
                        {sensor.id ===sensorDetails.sensor_id ? sensorDetails.calibrationSource : '---'}
                      </td>
                    </Fragment>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Card>}
      </div>
    </div>
  )
}

export default CalibrationAdjustmentDevice;