import React, { Fragment } from 'react';
import _ from 'lodash';
import { Table, Button, Checkbox } from 'antd';

import style from './Stage2.module.css';
import { useParams } from 'react-router-dom';


function EmptyTable(props) {
  const {panelId} = useParams();

  const columns = [
    {
      title: 'Sys. Name',
      dataIndex: 'device_id',
      key: 'deviceId',
      render: (deviceId, record) => {
        const device = _.find(props.devices, {id: deviceId});

        return (<Button type="link" style={{color: "black"}} onClick={() => props.selectSensor(record)}>{_.get(device, 'name') || deviceId}</Button>)
      },
      sorter: {
        multiple: 1,
        compare: (a, b) => {
          const deviceA = _.find(props.devices, {id: a.device_id}) || {name: a.device_id};
          const deviceB = _.find(props.devices, {id: b.device_id}) || {name: b.device_id};
  
          const aName = deviceA.name;
          const bName = deviceB.name;

          return aName.localeCompare(bName);
        }
      },
      filters: _.map(_.sortBy(props.devices, 'name'), device => ({text: device.name || device.id, value: device.id})),
      onFilter: (value, record) => {
        return record.device_id === value;
      }
    },{
      title: 'Port',
      dataIndex: 'port',
      key: 'port',
      filters: '12345678'.split('').map(port => ({text: port, value: parseInt(port)})),
      onFilter: (value, record) => {
        return record.port === value;
      },
      sorter: {
        multiple: 2,
        compare: (a, b) => a.port - b.port
      },
      render: (index) => index
    },{
      title: 'Raw/Temperature',
      key: 'pressureTemperature',
      render: (v, record) => `${record.rt}/${record.tempg}`
    }, {
      title: 'Min/Max',
      dataIndex: 'result',
      key: 'minmax',
      render: v => !_.isEmpty(v) ? `${_.minBy(v, 'rt_min').rt_min}/${_.maxBy(v, 'rt_max').rt_max}` : '-',
    }, {
      title: 'Stability',
      dataIndex: 'result',
      key: 'stabilityTest',
      render: () => '-',
    }, {
      title: 'Clay Test',
      dataIndex: 'result',
      key: 'clayTest',
      render: v => {
        return '-';
      },
    },
    {
      title: 'Cable Test',
      dataIndex: 'result',
      key: 'cableTest',
      render: v => {
        return '-'
      },
    }, {
      title: 'Action',
      key: 'action',
      render: (record) => {
      return (<Fragment>Glued <Checkbox defaultChecked={props.panel.stage_2_glued_sensors && props.panel.stage_2_glued_sensors.split(',').includes(record.id)} onChange={() => props.glueSensor(panelId || 1, record.id)}/></Fragment>)
      }
    }
  ]
   
  return (
    <Table
      dataSource={props.dataSource}
      columns={columns}
      rowKey='id'
      pagination={false}
      showSorterTooltip={false}
      scroll={{y: 'calc(100vh - 162px)'}}
      rowClassName={(record) => {
        return {
          [style.disabled]: true,
          [style.dry]: record.rt < props.panel.dry_max
        }
      }}
      rowSelection={{
        selectedRowKeys: [],
        getCheckboxProps: () => {
          return {
            disabled: true
          };
        },
      }}
    />
  )
}

export default EmptyTable;