import React, { Fragment } from 'react';
import _ from 'lodash';
import Switch from 'react-switch-m';
import { PlayCircleFilled, StopFilled } from '@ant-design/icons';

import style from './Stage2.module.css';
import FullTable from '../Stage2/FullTable';
import EmptyTable from '../Stage2/EmptyTable';
import Countdown from 'react-countdown';
import { Modal } from 'antd';

function Stage2(props) {
  const [modal, contextHolder] = Modal.useModal();
  const isDone = props.panel.stage_2_end && Date.now() > (new Date(props.panel.stage_2_end).getTime());
  const successStage2 = _.filter(props.sensors, sensor => _.get(sensor,'stage1.valid') && _.get(sensor,'stage2.valid')).length;
  const stage1SensorsIds = _.get(props, 'panel.stage_1_selected_sensors') ? _.get(props, 'panel.stage_1_selected_sensors').split(',') : [];
  const stage2SensorsIds = _.get(props, 'panel.stage_2_selected_sensors') ? _.get(props, 'panel.stage_2_selected_sensors').split(',') : [];

  const CountdownComponent = (
    <div className={style.statistic}>
      <Countdown date={props.panel.stage_2_end || Date.now()}>
        <Fragment>Sensor: {stage1SensorsIds.length} | Success: {successStage2 || stage2SensorsIds.length || 0} | Failed: {successStage2 ? stage1SensorsIds.length-successStage2 : stage1SensorsIds.length}</Fragment>
      </Countdown>
    </div>
  )

  return (
    <Fragment>
      <div className={style.panel}>
        <div className={style.actions}>
          {props.panel.stage_2_end && CountdownComponent}
        </div>
        <div>
          <Switch
            disabled={!props.isActionable}
            width={80}
            height={40}
            offColor="#ff004b"
            onColor="#4bff00"
            checked={props.isActive || false}
            className={style.nextButton}
            checkedIcon={<StopFilled />}
            uncheckedIcon={<PlayCircleFilled />}
            disableTouch={true}
            onChange={(valid) => {
              if(!props.isActionable) {
                return;
              }

              if(valid) {
                modal.confirm({title: '', content: (
                  <div>
                    you are about to start Stage 2 <br />
                    Are you sure?
                  </div>), onOk: ()=>props.onStart(), autoFocusButton: 'cancel'});
              } else {
                modal.confirm({title: '', content: (
                  <div>
                    you are about to stop Stage 2<br />
                    You will have to restart the entire stage after approve<br />
                    Are you sure?
                  </div>), onOk: ()=>props.onStop(), autoFocusButton: 'cancel'});
              }
            }}
          />
        </div>
      </div>
      {isDone ? <FullTable
        dataSource={props.sensors}
        {...props}
      /> : <EmptyTable
      dataSource={props.sensors}
      {...props}
      />}
      {contextHolder}
    </Fragment>
  )
}

export default Stage2;